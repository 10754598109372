<template>
  <div id="dashboard">
    <v-card-title class="px-0 mb-3">
      {{ t('Fanaty Dashboard') }}

      <v-spacer></v-spacer>
      <v-btn
        small
        class="white--text"
        color="btnPrimary"
        @click="$router.replace({ name: 'views-reports-view'})"
      >
        {{ $t('tooltip.back') }}
      </v-btn>
    </v-card-title>

    <v-row class="ma-0 text-center">
      <v-col
        cols="12"
        md="2"
        :class="{ 'pl-0': $vuetify.breakpoint.smAndUp }"
      >
        <v-select
          v-model="dateRangeFilter"
          class="bg--white"
          :items="dateRangeOptions"
          item-text="text"
          item-value="value"
          single-line
          outlined
          dense
          hide-details
          :placeholder="t('select.date_range')"
          @change="updateFilter('views-reports-fanaty', 'custom', dateRangeFilter)"
        ></v-select>
      </v-col>

      <v-col
        v-if="dateRangeFilter === 'C'"
        cols="12"
        md="2"
        class="pb-0"
      >
        <v-menu
          v-model="modalFrom"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFrom"
              :placeholder="t('transactions.from_date')"
              outlined
              readonly
              dense
              class="invoice-list-status bg--white"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            color="secondary"
            :min="minDate"
            :max="nowDate"
            @input="modalFrom = false"
            @change="updateFilter('views-reports-fanaty', 'dateFrom', dateFrom)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="dateRangeFilter === 'C'"
        cols="12"
        md="2"
        class="pb-0"
      >
        <v-menu
          v-model="modalTo"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateTo"
              :placeholder="t('transactions.to_date')"
              outlined
              readonly
              dense
              class="invoice-list-status bg--white"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            color="secondary"
            :min="dateFrom"
            :max="nowDate"
            @input="modalTo = false"
            @change="updateFilter('views-reports-fanaty', 'dateTo', dateTo)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="userData.role === 'A'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="customOrganization"
          class="bg--white"
          :label="t('organizations.organization')"
          :placeholder="t('organizations.organization')"
          :items="organizationsOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          @change="updateFilter('views-reports-fanaty', 'organization', customOrganization)"
        ></v-select>
      </v-col>

      <v-col
        v-if="userData.role === 'A'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="customFacility"
          class="bg--white"
          :label="t('facilities.facility')"
          :placeholder="t('facilities.facility')"
          :items="facilitiesOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          :disabled="!customOrganization"
          :append-icon="facilitiesOptions.length === 1 || !customOrganization ? null : icons.mdiMenuDown"
          :readonly="facilitiesOptions.length === 1 || !customOrganization"
          @change="updateFilter('views-reports-fanaty', 'facility', customFacility)"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="mb-5 match-height">
      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-vertical
          :change="computedTotalFacilitiesOptions.change"
          :color="computedTotalFacilitiesOptions.color"
          :icon="computedTotalFacilitiesOptions.icon"
          :statistics="computedTotalFacilitiesOptions.statistics"
          :tooltip="computedTotalFacilitiesOptions.tooltip"
          :start-title="t('statistics.active_facility')"
          :subtitle="''"
          :show-btn-dots="computedTotalFacilitiesOptions.showBtnDots"
          :show-change="computedTotalFacilitiesOptions.showChange"
        ></statistics-card-vertical>
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-vertical
          :change="computedTotalFieldsOptions.change"
          :color="computedTotalFieldsOptions.color"
          :icon="computedTotalFieldsOptions.icon"
          :statistics="computedTotalFieldsOptions.statistics"
          :tooltip="computedTotalFieldsOptions.tooltip"
          :start-title="t('statistics.active_field')"
          :subtitle="''"
          :show-btn-dots="computedTotalFieldsOptions.showBtnDots"
          :show-change="computedTotalFieldsOptions.showChange"
        ></statistics-card-vertical>
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-vertical
          :change="computedTotalCamerasOptions.change"
          :color="computedTotalCamerasOptions.color"
          :icon="computedTotalCamerasOptions.icon"
          :statistics="computedTotalCamerasOptions.statistics"
          :tooltip="computedTotalCamerasOptions.tooltip"
          :start-title="t('statistics.active_camera')"
          :subtitle="''"
          :show-btn-dots="computedTotalCamerasOptions.showBtnDots"
          :show-change="computedTotalCamerasOptions.showChange"
        ></statistics-card-vertical>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="12"
          >
            <statistics-card-vertical
              :change="computedTotalUsersOptions.change"
              :color="computedTotalUsersOptions.color"
              :icon="computedTotalUsersOptions.icon"
              :statistics="computedTotalUsersOptions.statistics"
              :tooltip="t('statistics.active_app_users_tooltip')"
              :start-title="t('statistics.active_app_users')"
              :subtitle="''"
              :show-btn-dots="computedTotalUsersOptions.showBtnDots"
              :show-change="computedTotalUsersOptions.showChange"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <AnalyticsCardSalesByCountries
          :title="t('statistics.app_users_number')"
          :items="userFacility"
          :show-vertical-dots="true"
          :tooltip="t('statistics.app_users_number_tooltip')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="12"
          >
            <statistics-card-vertical
              :change="computedTotalAppSessionsOptions.change"
              :color="computedTotalAppSessionsOptions.color"
              :icon="computedTotalAppSessionsOptions.icon"
              :statistics="computedTotalAppSessionsOptions.statistics"
              :tooltip="t('statistics.app_sessions_tooltip')"
              :start-title="t('statistics.app_sessions')"
              :subtitle="''"
              :show-btn-dots="computedTotalAppSessionsOptions.showBtnDots"
              :show-change="computedTotalAppSessionsOptions.showChange"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <AnalyticsCardSalesByCountries
          :title="t('statistics.actions_app_by_user')"
          :items="actionsAppUser"
          :show-vertical-dots="true"
          :tooltip="t('statistics.actions_app_by_user_tooltip')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="12"
      >
        <weekly-overview
          :chart-data="computedAuxChartData"
          :chart-categories="computedChartCategories"
          :start-title="t('statistics.order_number')"
          :chart-color="chartColors"
          chart-height="200"
          :show-btn-detail="false"
          :show-text-detail="false"
          :show-btn-dots="false"
        ></weekly-overview>
      </v-col>

      <v-col
        cols="12"
        sm="12"
      >
        <v-card>
          <v-card-title>
            <span>{{ t('statistics.order_number') }}</span>
          </v-card-title>
          <v-card-text>
            <apex-chart-line-chart
              :chart-series="computedAuxChartData"
              :chart-options="computedAreaChartOptions"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
      >
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="3"
          >
            <statistics-card-vertical
              :change="computedTotalOrdersNumberOptions.change"
              :color="computedTotalOrdersNumberOptions.color"
              :icon="computedTotalOrdersNumberOptions.icon"
              :statistics="computedTotalOrdersNumberOptions.statistics"
              :tooltip="computedTotalOrdersNumberOptions.tooltip"
              :start-title="t('statistics.order_number')"
              :subtitle="''"
              :show-btn-dots="computedTotalOrdersNumberOptions.showBtnDots"
              :show-change="computedTotalOrdersNumberOptions.showChange"
            ></statistics-card-vertical>
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <statistics-card-vertical
              :change="computedTotalOrdersOptions.change"
              :color="computedTotalOrdersOptions.color"
              :icon="computedTotalOrdersOptions.icon"
              :statistics="computedTotalOrdersOptions.statistics"
              :tooltip="computedTotalOrdersOptions.tooltip"
              :start-title="t('statistics.orders')"
              :subtitle="''"
              :show-btn-dots="computedTotalOrdersOptions.showBtnDots"
              :show-change="computedTotalOrdersOptions.showChange"
            ></statistics-card-vertical>
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <statistics-card-vertical
              :change="computedTotalComissionsOptions.change"
              :color="computedTotalComissionsOptions.color"
              :icon="computedTotalComissionsOptions.icon"
              :statistics="computedTotalComissionsOptions.statistics"
              :tooltip="computedTotalComissionsOptions.tooltip"
              :start-title="t('statistics.comissions')"
              :subtitle="''"
              :show-btn-dots="computedTotalComissionsOptions.showBtnDots"
              :show-change="computedTotalComissionsOptions.showChange"
            ></statistics-card-vertical>
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <statistics-card-vertical
              :change="computedTotalRefundsOptions.change"
              :color="computedTotalRefundsOptions.color"
              :icon="computedTotalRefundsOptions.icon"
              :statistics="computedTotalRefundsOptions.statistics"
              :tooltip="computedTotalRefundsOptions.tooltip"
              :start-title="t('statistics.refunds_amount')"
              :subtitle="''"
              :show-btn-dots="computedTotalRefundsOptions.showBtnDots"
              :show-change="computedTotalRefundsOptions.showChange"
            ></statistics-card-vertical>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <statistics-card-vertical
              :change="computedTotalNumberVideosOptions.change"
              :color="computedTotalNumberVideosOptions.color"
              :icon="computedTotalNumberVideosOptions.icon"
              :statistics="computedTotalNumberVideosOptions.statistics"
              :tooltip="t('statistics.number_videos_tooltip')"
              :start-title="t('statistics.number_videos')"
              :subtitle="''"
              :show-btn-dots="computedTotalNumberVideosOptions.showBtnDots"
              :show-change="computedTotalNumberVideosOptions.showChange"
            ></statistics-card-vertical>
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <statistics-card-vertical
              :change="computedTotalAvgVideoOptions.change"
              :color="computedTotalAvgVideoOptions.color"
              :icon="computedTotalAvgVideoOptions.icon"
              :statistics="computedTotalAvgVideoOptions.statistics"
              :tooltip="t('statistics.avg_per_video_tooltip')"
              :start-title="t('statistics.avg_per_video')"
              :subtitle="''"
              :show-btn-dots="computedTotalAvgVideoOptions.showBtnDots"
              :show-change="computedTotalAvgVideoOptions.showChange"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <AnalyticsCardSalesByCountries
          :title="t('statistics.sales_by_facility')"
          :items="salesByFacility"
          :show-vertical-dots="false"
          :tooltip="'tooltip'"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="12"
          >
            <statistics-card-vertical
              :change="computedTotalUniqueViewsOptions.change"
              :color="computedTotalUniqueViewsOptions.color"
              :icon="computedTotalUniqueViewsOptions.icon"
              :statistics="computedTotalUniqueViewsOptions.statistics"
              :tooltip="t('statistics.unique_views_tooltip')"
              :start-title="t('statistics.unique_views')"
              :subtitle="''"
              :show-btn-dots="computedTotalUniqueViewsOptions.showBtnDots"
              :show-change="computedTotalUniqueViewsOptions.showChange"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <AnalyticsCardSalesByCountries
          :title="t('statistics.unique_views_by_facility')"
          :items="uniqueViewsByFacility"
          :show-vertical-dots="false"
          :tooltip="'tooltip'"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="12"
          >
            <statistics-card-vertical
              :change="computedTotalRegisteredUser.change"
              :color="computedTotalRegisteredUser.color"
              :icon="computedTotalRegisteredUser.icon"
              :statistics="computedTotalRegisteredUser.statistics"
              :tooltip="computedTotalRegisteredUser.tooltip"
              :start-title="t('statistics.registered_app_users')"
              :subtitle="''"
              :show-btn-dots="computedTotalRegisteredUser.showBtnDots"
              :show-change="computedTotalRegisteredUser.showChange"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <AnalyticsCardSalesByCountries
          :title="t('statistics.registered_app_users_by_facility')"
          :items="registeredUserByFacility"
          :show-vertical-dots="false"
          :tooltip="'tooltip'"
        />
      </v-col>

      <v-col
        cols="12"
        sm="12"
      >
        <v-card>
          <v-card-title>
            <span>{{ t('dashboard.revenue') }}</span>
          </v-card-title>
          <v-card-subtitle>
            {{ t('dashboard.orders_revenue') }}
          </v-card-subtitle>
          <v-card-text>
            <apex-chart-balance
              :options="computedRevenueChartOptions"
              :series="computedRevenueSeries"
            ></apex-chart-balance>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
      >
        <v-card>
          <v-card-title>
            <span>{{ t('dashboard.created_highlights') }}</span>
          </v-card-title>
          <v-card-subtitle>
            {{ t('dashboard.numbe_of_highlights_created') }}
          </v-card-subtitle>
          <v-card-text>
            <apex-chart-balance
              :options="computedHighlightCreatedChartOptions"
              :series="computedHighlightCreatedSeries"
            ></apex-chart-balance>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
      >
        <v-card>
          <v-card-title>
            <span>{{ t('dashboard.shared_highlights') }}</span>
          </v-card-title>
          <v-card-subtitle>
            {{ t('dashboard.numbe_of_highlights') }}
          </v-card-subtitle>
          <v-card-text>
            <apex-chart-balance
              :options="computedActivityUserChartOptions"
              :series="computedActivityUserSeries"
            ></apex-chart-balance>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiCalendarArrowLeft, mdiCalendarArrowRight, mdiHelp } from '@mdi/js'
import { computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatCurrency, formatStdDate } from '@core/utils'
import useSelectOptions from '@core/utils/useSelectOptions'

import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import ApexChartBalance from '../../charts-and-maps/charts/apex-chart/ApexChartBalance.vue'
import ApexChartLineChart from '../../charts-and-maps/charts/apex-chart/ApexChartLineChart.vue'
import AnalyticsCardSalesByCountries from '../analytics/AnalyticsCardSalesByCountries.vue'

// composition function
import useFanatyDashboard from './useFanatyDashboard'

export default {
  components: {
    WeeklyOverview: () => import('@/views/pages/cards/card-chart/cards/WeeklyOverview.vue'),
    StatisticsCardVertical,
    ApexChartBalance,
    ApexChartLineChart,
    AnalyticsCardSalesByCountries,
  },
  setup() {
    const {
      userData,
      dateRangeOptions,
      dateRangeFilter,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      minDate,
      maxDate,
      nowDate,

      computedActivityUserSeries,
      computedActivityUserChartOptions,
      computedHighlightCreatedSeries,
      computedHighlightCreatedChartOptions,
      computedRevenueSeries,
      computedRevenueChartOptions,
      computedAuxChartData,
      computedChartCategories,
      chartColors,
      computedChartData,
      chartCategoriesWeekdays,
      computedTotalOrdersNumberOptions,
      computedTotalOrdersOptions,
      computedTotalComissionsOptions,
      computedTotalRefundsOptions,

      computedTotalFacilitiesOptions,
      computedTotalFieldsOptions,
      computedTotalCamerasOptions,
      computedTotalUsersOptions,
      computedTotalAppSessionsOptions,
      computedTotalNumberVideosOptions,
      computedTotalUniqueViewsOptions,
      computedTotalAvgVideoOptions,
      computedTotalRegisteredUser,

      chartDashboardOptions,
      chartRecordigActivityOptions,
      chartFanatyOptions,

      computedAreaSeries,
      computedAreaChartOptions,

      userFacility,
      salesByFacility,
      actionsAppUser,
      registeredUserByFacility,
      uniqueViewsByFacility,

      organizationsOptions,
      facilitiesOptions,
      customOrganization,
      customFacility,

      t,
      fetchSummary,
      updateFilter,
      getFilterByModule,
    } = useFanatyDashboard()
    const { configObjFacility } = useSelectOptions()
    const vm = getCurrentInstance().proxy

    const computedDateFrom = computed(() => formatStdDate(dateFrom.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale))
    const computedDateTo = computed(() => formatStdDate(dateTo.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale))

    onMounted(async () => {
      const filtersStored = getFilterByModule('views-reports-fanaty')
      if (filtersStored.organization) customOrganization.value = filtersStored.organization
      if (filtersStored.facility) customFacility.value = filtersStored.facility
      if (filtersStored.custom) dateRangeFilter.value = filtersStored.custom
      if (filtersStored.dateFrom) dateFrom.value = filtersStored.dateFrom
      if (filtersStored.dateTo) dateTo.value = filtersStored.dateTo

      await fetchSummary()
    })

    return {
      userData,

      dateRangeOptions,
      dateRangeFilter,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      minDate,
      maxDate,
      nowDate,

      computedActivityUserSeries,
      computedActivityUserChartOptions,
      computedHighlightCreatedSeries,
      computedHighlightCreatedChartOptions,
      computedRevenueSeries,
      computedRevenueChartOptions,

      computedTotalOrdersNumberOptions,
      computedTotalOrdersOptions,
      computedTotalComissionsOptions,
      computedTotalRefundsOptions,
      computedChartData,
      computedChartCategories,
      chartColors,
      chartCategoriesWeekdays,
      computedAuxChartData,

      computedTotalFacilitiesOptions,
      computedTotalFieldsOptions,
      computedTotalCamerasOptions,
      computedTotalUsersOptions,
      computedTotalAppSessionsOptions,
      computedTotalNumberVideosOptions,
      computedTotalUniqueViewsOptions,
      computedTotalAvgVideoOptions,
      computedTotalRegisteredUser,

      chartDashboardOptions,
      chartRecordigActivityOptions,
      chartFanatyOptions,

      computedAreaSeries,
      computedAreaChartOptions,
      computedDateFrom,
      computedDateTo,
      configObjFacility,

      userFacility,
      salesByFacility,
      actionsAppUser,
      registeredUserByFacility,
      uniqueViewsByFacility,

      organizationsOptions,
      facilitiesOptions,
      customOrganization,
      customFacility,
      formatStdDate,

      icons: {
        mdiCalendarArrowLeft,
        mdiCalendarArrowRight,
        mdiHelp,
      },

      // i18n
      t,

      avatarText,
      formatCurrency,

      fetchSummary,
      updateFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
#payment-list {
  .payment-list-actions {
    max-width: 7.81rem;
  }
  .payment-list-search {
    max-width: 10.625rem;
  }
  .payment-list-status {
    max-width: 11.3rem;
  }
}
</style>
